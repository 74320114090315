<template>
  <div class="home">
    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <user-nav></user-nav>
    </v-navigation-drawer>

    <v-app-bar app dark color="primary">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <img class="mr-3" :src="require('@/assets/icon.svg')" height="40"/>
      <v-toolbar-title class="text-uppercase">{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <user-icon></user-icon>
      <locales-nav></locales-nav>
    </v-app-bar>
    <v-row no-gutters>
          <v-col cols="10">
              <v-breadcrumbs :items="breadItems" class="white">
                    <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                    </template>
                  <template v-slot:item="{ item }">
                    <v-breadcrumbs-item
                      :href="item.href"
                      :disabled="item.disabled"
                    >
                      <span v-if="!item.isparam">{{ $t('router.'+item.text) }}</span>
                      <span v-else>{{ item.text }}</span>
                    </v-breadcrumbs-item>
                  </template>
              </v-breadcrumbs>
          </v-col>  
          <v-col cols="2" class="text-center" >
             <v-btn icon @click="refresh" color="primary" class="my-3">
                <v-icon>mdi-reload</v-icon>
              </v-btn>
          </v-col> 
          <v-col cols="12">
            <v-progress-linear
              color="grey"
              height="15"
              :indeterminate="loading"
            ></v-progress-linear>
          </v-col>  
    </v-row>
    <router-view></router-view>
  </div>
</template>

<script>
import UserIcon from '@/components/Layout/user/UserIcon.vue'
import UserNav from '@/components/Layout/user/UserNav.vue'
import LocalesNav from '@/components/Layout/user/LocalesNav.vue'
import {mapGetters} from "vuex";

export default {
  name: 'Dashboard',
  components:{
          "user-icon": UserIcon,
          "user-nav": UserNav,
          "locales-nav": LocalesNav,
  },
  data: () => ({
      drawer: null,
      title: process.env.VUE_APP_BASE_NAME
  }),
  computed:{
     ...mapGetters({
            loading: 'loading/loading',
        }),
    breadItems() {
      var breadItems = [];
      breadItems = breadItems.concat(this.$route.meta.breadCrumbs)
      if(this.$route.params){
        var keys = Object.keys(this.$route.params)
        keys.forEach(key => {
          var last = breadItems[breadItems.length - 1];
          last.disabled = true
          breadItems.push({text:this.$route.params[key],disabled:true,isparam:true})
        });
      }
      return breadItems
     },
  },
  methods:{
    refresh(){
      location.reload(true);
    }
  }
}
</script>